import React from "react"
import { Link, navigate } from "gatsby"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import HubspotForm from "react-hubspot-form"
import { Tabbordion, TabPanel, TabContent } from "react-tabbordion"
import useLiveControl from "../controlled/useLiveControl"
// import LiveControlSpinner from "../components/liveControlSpinner"
import Seo from "../components/seo"

// accordian
const blockElements = {
  animator: "accordion-animator--unchecked",
  content: "accordion-content",
  panel: "accordion-panel",
  label: "accordion-title",
}

const customLink = (url, e) => {
  navigate(url)
}

const HomeImprovementFinancing = () => {
  const { isStaging } = useLiveControl()

  // if (runningChecks || !isStaging) return <LiveControlSpinner />

  return (
    <Layout>
      <Seo
        title={"Home Improvement Financing"}
        description={
          "Regions EnerBank helps homeowners get the home improvement financing they need to achieve the home of their dreams. Contractors benefit by offering EnerBank financing to their customers."
        }
      />
      <Helmet>
        <body id={`${isStaging ? "rebrand" : null }`} className="page-home-improvement-financing" />
      </Helmet>
      {isStaging ? (
        <section 
        className="banner-area"
        id="home-improvement-financing-banner-section">
          <div className="container banner-container"
              style={{
                        backgroundImage:
                          "url(" +
                          "https://gbdevpress.enerbank.com/wp-content/themes/enerbank/img/hero-img.jpg" +
                          ")",
                      }}
              >
            <div className="relative banner-area-text-container">
                    <div className="container text-center">
                      <div className="identifier-container row-fluid">
                        <div className="element span12">
                            <div className="rhif-identifier span5">
                                <h4>HOME IMPROVEMENT FINANCING</h4>
                            </div>
                            <div className="right-angle span2"></div>
                        </div>
                      </div>
                      <h1 className="text-white text-left desktop" style={{ padding: "80 0" }}>
                       Home Improvement Financing from Regions
                      </h1>
                    </div>
                  </div>
            </div>
            <div className="container text-center mobile">
            <h1 className="text-white text-left" style={{ padding: "80 0" }}>
             Home Improvement Financing from Regions
            </h1>
          </div>
          </section>
        ) : (
       <section
        className="banner-area"
        id="home-improvement-financing-banner-section"
        style={{
          backgroundImage:
            "url(" +
            "https://gbdevpress.enerbank.com/wp-content/themes/enerbank/img/hero-img.jpg" +
            ")",
        }}
      >
        <div className="row align-items-center relative banner-area-text-container">
          <div className="container text-center">
            <h1 className="text-white" style={{ padding: "80 0" }}>
              Home Improvement Financing from Regions | EnerBank
            </h1>
          </div>
        </div>
      </section>
      )}

      <section
        className="paragraph-text-block"
        id="contractor-financing-paragraph-text-block"
      >
        <div className="container">
          <div className="justify-content-center">
            <div className={`${isStaging ? "" : "pb-5"} pt-5`}>
              <div>
                <p>
                  When it&#39;s time to start a home improvement project, people
                  often find the cost of the project they really want often
                  exceeds their cash on hand. Research shows that over 55% of
                  home improvement projects are financed in some way<sup>*</sup>.
                </p>
                <p>
                  Some homeowners choose to use a credit card, despite high
                  interest rates. Others spend time and energy comparing fees
                  and rates from banks and credit unions. Some homeowners,
                  however, benefit from working with a contractor that offers
                  in-house financing options. That kind of convenience is hard
                  to beat.
                </p>
                <p>
                  <small>
                    <sup>*</sup>HIRI TFG Monthly report, released October 2023
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isStaging ? (
      <section className="why-homeowners-choose-regions bg-light-gray">
        <div className="py-4">
          <div className="container">
            <h2 className="text-center">
              Why homeowners choose<br/> Regions Home Improvement Financing
            </h2>
          </div>
        </div>
        <div className="container section-padding py-5 my-3">
          <div className="col-12">
            <p className="">
              Regions Home Improvement Financing is a specialized home improvement lender – that means
              we&#39;re experienced when it comes to providing financing for
              home improvement projects like yours. Since it&#39;s our
              specialty, we&#39;ve developed processes and payment options that
              are tailored to home improvement needs. In the over two decades
              that we&#39;ve been in business, we&#39;ve approved over one million
              home improvement loans.
            </p>
              <p>
                Instead of saving up or completing the job in stages, you can get
                the entire project completed now, without compromises. With a Regions 
                Home Improvement Financing loan, your home is not used as collateral, there&#39;s no
                application fee, no closing or appraisal costs, and no prepayment
                penalties. Our paperless application process is quick & easy, and
                you can often receive a credit decision within seconds. Approvals
                are valid for 120-180 days, giving you plenty of time to complete
                your home improvement project.
              </p>
            </div>
          </div>
          <div className="container-lg">
          <div className="icon-cards row justify-content-center">
            <div className="col-md-2">
              <div className="card justify-content-inherit">
                <div className="card-image">
                  <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/3-quick-easy-paperless-application-methods.png" className="icon" alt="Quick and easy application process" width="80" /> 
                </div>
                <div className="card-body">
                  <h3>Quick and Easy application process</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card justify-content-inherit">
                <div className="card-image">
                  <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/us-based-lending-customer-service.png" className="icon" alt="U.S.Based Lending & Customer Service" /> 
                </div>
                <div className="card-body">
                  <h3>U.S. Based Lending & Customer Service</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card justify-content-inherit">
                <div className="card-image">
                  <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/fdic-insured.png" className="icon" alt="FDIC Insured" /> 
                </div>
                <div className="card-body">
                  <h3>FDIC<br/> Insured</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card justify-content-inherit">
                <div className="card-image">
                  <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/high-approval-rates.png" className="icon" alt="High approlval rates" />
                </div>
                <div className="card-body">
                  <h3>High Approval Rates</h3>
                </div>
              </div>
              </div>
              <div className="col-md-2">
                <div className="card justify-content-inherit">
                  <div className="card-image payment-options">
                    <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/07/several-payment-options.png" className="icon" alt="several payment options" />
                  </div>
                  <div className="card-body">
                    <h3>Several Payment Options</h3>
                  </div>
                </div>
              </div>
            
          </div>
          {/* <div className="benefit-option mt-5 pt-5">
              <ul>
                <li className="quick-easy">
                  <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/3-quick-easy-paperless-application-methods.png" className="icon" alt="Quick and easy application process" width="80" /> 
                  <h4>Quick and Easy application process</h4>
                </li>
                <li className="us-based">
                  <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/us-based-lending-customer-service.png" className="icon" alt="U.S.Based Lending & Customer Service" /> 
                  <h4>U.S. Based Lending & Customer Service</h4>
                </li>
		            <li className="fdic-insured">
                  <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/fdic-insured.png" className="icon" alt="FDIC Insured" /> 
                  <h4>FDIC<br/> Insured</h4>
                </li>
		            <li className="high-approval-rates">
                  <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/high-approval-rates.png" className="icon" alt="High approlval rates" /> <h4>High Approval<br/> Rates</h4>
                </li>
                <li className="several-payment-options">
                  <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/07/several-payment-options.png" className="icon" alt="several payment options" /> 
                  <h4>Several Payment<br/> Options</h4>
                </li>               
              </ul>
            </div> */}
        </div>
      </section>
      ) : (
        <section className="why-homeowners-choose-regions">
        <div className="bg-blue py-4">
          <div className="container">
            <h2 className="text-center text-white">
              Why Homeowners Choose EnerBank
            </h2>
          </div>
        </div>
        <div className="container section-padding py-5 my-3">
          <div className="">
            <p className="">
              EnerBank USA is a specialized home improvement lender – that means
              we&#39;re experienced when it comes to providing financing for
              home improvement projects like yours. Since it&#39;s our
              specialty, we&#39;ve developed processes and payment options that
              are tailored to home improvement needs. In the over two decades
              that we&#39;ve been in business, we&#39;ve approved over one million
              home improvement loans.
            </p>
            <p>
              Instead of saving up or completing the job in stages, you can get
              the entire project completed now, without compromises. With an
              EnerBank loan, your home is not used as collateral, there&#39;s no
              application fee, no closing or appraisal costs, and no prepayment
              penalties. Our paperless application process is quick & easy, and
              you can often receive a credit decision within seconds. Approvals
              are valid for 120-180 days, giving you plenty of time to complete
              your home improvement project.
            </p>
          </div>
          <div className="app-process row">
            <ul className="col-12 px-3">
              <li>
                <div className="box-circle icon regions-green content-center">
                  <img
                    className="img-fluid"
                    src="https://gbdevpress.enerbank.com/wp-content/uploads/2023/07/quck-easy-app-process.png"
                    alt="quick and easy process"
                  />
                </div>
                <p>
                  Quick & Easy
                  <br />
                  Application Process
                </p>
              </li>
              <li>
                <div className="box-circle icon regions-green content-center">
                  <img
                    className="img-fluid"
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/10/us-based-lending-customer-service.png"
                    alt="customer service"
                  />
                </div>
                <p>
                  U.S. Based Lending &<br />
                  Customer Service
                </p>
              </li>
              <li>
                <div className="box-circle icon regions-green content-center">
                  <img
                    className="img-fluid"
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/05/member-fdic.svg"
                    alt="fdic member"
                  />
                </div>
                <p>
                  Regions Bank
                  <br />
                  FDIC
                </p>
              </li>
              <li>
                <div className="box-circle icon regions-green content-center">
                  <img
                    className="img-fluid"
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/10/high-approval-rates.png"
                    alt="approval rates"
                  />
                </div>
                <p>
                  High Approval
                  <br />
                  Rates
                </p>
              </li>
              <li>
                <div className="box-circle icon regions-green content-center">
                  <img
                    className="img-fluid"
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/10/several-loan-types.png"
                    alt="payment options"
                  />
                </div>
                <p>
                  Several Payment
                  <br />
                  Options
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      )}
      <section className={`${isStaging ? "rds-marquee marquee-teal" : "homeowner-referral-section bg-charcoal section-padding py-5"}`}>
      {isStaging ? (
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-5 order-2 order-md-1">
              <h2 className="text-white mb-5">Refer a contractor</h2>
              <p className="text-white">Refer a contractor to Regions&apos; Express Loan Program<br/> and receive a $300 Amazon.com Gift Card!</p>
              <div className="row justify-content-center align-items-top text-center pt-5">
                <div className="col-md-6 mb-4">
                  <button type="button" className="btn btn-info btn-gray" onClick={() =>
                        customLink("https://info.enerbank.com/homeowner-referral/")}>I'm a Homeowner</button>
                </div>
                <div className="col-md-6 text-center">
                  <button type="button" className="btn btn-info btn-gray" onClick={() =>
                        customLink("https://info.enerbank.com/contractor-referral")}>I'm a Contractor</button>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2 text-center">
              <img
                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/amazon-300-gift-card-2.png"
                  className="mb-3"
                  alt="$300 Amazon gift card"
                />
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <h2 className="text-center mb-5 text-white">
            $300 Homeowners Referral Opportunity
          </h2>
          <div className="row pb-5">
            <div className="col-md-6">
              <p className="text-white">
                You can refer a contractor not currently active with EnerBank to
                become an authorized EnerBank contractor and get rewarded for
                improving your home. Once your loan application is submitted to
                EnerBank, you&apos;ll receive a $300 Amazon Gift Card!
              </p>
              <p className="text-white">
                <Link
                  to="/homeowner-referral-program-details/"
                  className="eb-link-white"
                >
                  Terms and Conditions
                </Link>{" "}
                Apply.{" "}
              </p>
            </div>
            <div className="col-md-6">
              <div className="gift-card">
                <img
                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/10/amazon-gift-card.png"
                  alt="Amazon gift card"
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 py-5">
              <h3 className="text-white text-center">Click below if </h3>
            </div>
            <div className="col-md-3 link-card">
              <a
                data-toggle="collapse"
                data-parent="#accordion"
                href="#contractor"
                data-target="#contractor"
              >
                <div className="card">
                  <div className="card-image">
                    <img
                      src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/07/contractor-icon.png"
                      alt="contractor"
                    />
                  </div>
                  <div className="card-body">
                    <p>
                      I&apos;m a<br />
                      Contractor
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-3 link-card">
              <a
                data-toggle="collapse"
                data-parent="#accordion"
                href="#homeowner"
                data-target="#homeowner"
              >
                <div className="card">
                  <div className="card-image">
                    <img
                      src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/10/homeowner-referring-icon.png"
                      alt="home owner"
                    />
                  </div>
                  <div className="card-body">
                    <p>
                      I&apos;m a<br />
                      Homeowner
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 pt-5"></div>
          </div>
        </div>
      )}
      </section>
      {isStaging ? (
        null 
      ) : (
      <section className="bg-light-gray">
        <div className="container">
          <Tabbordion
            blockElements={blockElements}
            // animateContent={"marginTop"}
            initialPanels={[{ checked: true, index: 1 }]}
            mode={"toggle"}
            className="accordion"
            id="accordion"
            name="accordion"
          >
            <TabPanel>
              {/* <TabLabel className="w-100 text-left btn btn-link">
                
                        </TabLabel> */}
              <TabContent id="contractor" className="form-section">
                <div className="container">
                  <div className="row justify-content-center relative">
                    <div className="col-md-12 pb-5">
                      <h2 className="text-center">
                        <span className="eb-green">I'm a Contractor</span>
                      </h2>
                    </div>
                    <div className="col-md-6">
                      <div id="refer-form" className="">
                        <HubspotForm
                          portalId="381510"
                          formId="d5ed3b50-8f3b-421d-8209-6509568a5d01"
                          onSubmit={() => this.handleFormSubmit}
                          onReady={form => console.log("Form ready!")}
                          loading={<div>Loading...</div>}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 who-why-refer">
                      <p className="">
                        With over{" "}
                        <span className="text-blue">
                          <strong>
                            two decades of experience in the home improvement
                            industry
                          </strong>
                        </span>
                        , Regions | EnerBank is a secure and stable option for
                        your contractor financing needs. With loans, tools, and
                        resources built for contractors, payment options from
                        EnerBank are easy to both integrate into your existing
                        processes and offer to your customers.
                      </p>
                      <p className="">
                        Contractors who offer{" "}
                        <span className="text-blue">
                          <strong>100% of their customers</strong>
                        </span>{" "}
                        a choice of payment options can:
                      </p>
                      <div className="row justify-content-center pymt-options">
                        <div className="col-md-6">
                          <div className="card">
                            <div className="card-body">
                              <div className="card-image">
                                <img
                                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/improve-cashflow.png"
                                  alt="cash flow"
                                />
                              </div>
                              <p className="text-center">
                                Improve
                                <br />
                                Cash Flow
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card">
                            <div className="card-body">
                              <div className="card-image">
                                <img
                                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/increase-leads.png"
                                  alt="increase leads"
                                />
                              </div>
                              <p className="text-center">
                                Increase
                                <br />
                                Leads
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card">
                            <div className="card-body">
                              <div className="card-image">
                                <img
                                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/boost-close-rates.png"
                                  alt="boost close rate"
                                />
                              </div>
                              <p className="text-center">
                                Boost
                                <br />
                                Close Rate
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card">
                            <div className="card-body">
                              <div className="card-image">
                                <img
                                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/grow-project-size.png"
                                  alt="grow project size"
                                />
                              </div>
                              <p className="text-center">
                                Grow Average
                                <br />
                                Project Size
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabContent>
              <TabContent id="homeowner" className="form-section">
                <div className="container">
                  <div className="row justify-content-center relative">
                    <div className="col-md-12 pb-5">
                      <h2 className="text-center">
                        <span className="text-blue">Homeowner</span>
                      </h2>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 who-why-refer">
                      <p>
                        Get your next dream project started with a loan from
                        EnerBank USA. Simply refer a contractor not currently
                        active with EnerBank to become an authorized EnerBank
                        contractor and get rewarded for improving your home.
                        Once your loan application is submitted to EnerBank,
                        you&apos;ll receive a $300 Amazon Gift Card!
                        <br />
                        <a href="/homeowner-referral-program-details">
                          Terms and Conditions Apply
                        </a>
                      </p>
                      <div className="gift-card">
                        <img
                          src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/10/amazon-gift-card-300.png"
                          alt="Amazon gift card"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div id="referred-form" className="hs-form">
                        <HubspotForm
                          portalId="381510"
                          formId="c2c00221-fc69-48c4-bbd6-2135ae5bfcae"
                          onSubmit={() => this.handleFormSubmit}
                          onReady={form => console.log("Form ready!")}
                          loading={<div>Loading...</div>}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </TabContent>
            </TabPanel>
          </Tabbordion>
        </div>
      </section>
      )}
      {isStaging ? (
      <section className="bg-light-gray section-padding why-contractors-enerbank py-5">
        <div className="container">
          <div className="row pb-5">
            <div className="col-md-6">
              <h2 className="pb-5 pt-2">
                Why so many contractors choose Regions
              </h2>
              <p className="mb-4">
                No matter how good a contractor is at their job, they won&apos;t
                stay in business if they can&apos;t attract customers. It&apos;s
                vital for contractors to increase their leads and close rate so
                they can show off their ability. When contractors offer a choice 
                of home improvement financing from Regions, they can see an
                increase in their leads as well bigger project sizes.
              </p>
            </div>
            <div className="col-md-6 text-center">
              <iframe
                className="pt-2"
                src="https://player.vimeo.com/video/825916381?h=a20d926863"
                width="540"
                height="315"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
                title="EnerBank USA - Contractor Financing"
              ></iframe>
            </div>
          </div>
          <div className="icon-cards row">
            <div className="col-md-3">
              <div className="card justify-content-inherit">
                <div className="card-image">
                    <img
                      src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/07/flexibility.png"
                      alt="business expansion"
                    />
                  </div>
                <div className="card-body">
                  <h3>Increase Business Expansion</h3>
                  <p>
                    Research shows that over 55% of home improvement projects
                    are financed in some way<sup>*</sup>. By advertising
                    financing to your customers, you could see a significant
                    increase in leads.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card justify-content-inherit">
                <div className="card-image">
                    <img
                      src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/07/bar-graph-trending-up.png"
                      alt="stay-ahead"
                    />
                </div>
                <div className="card-body">
                  <h3>Stay Ahead of Your Competitors</h3>
                  <p>
                    With so many customers using financing, you may be missing
                    out on opportunities. Those lost customers end up with a
                    competitor, leaving you to settle for the leftovers. By
                    offering financing options, you can retain those customers
                    and stay ahead of the competition.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card justify-content-inherit">
                <div className="card-image">
                    <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/07/several-payment-options.png" class="icon" alt="multiple payment options" />
                </div>
                <div className="card-body">
                  <h3>Provide Customers with Options</h3>
                  <p>
                    Financing options let you offer various products at
                    different price points that can fit almost any budget — a
                    "good, better, best" approach to your sales process. When
                    customers see they have greater spending power and more
                    flexibility in their choices, they can be more confident in
                    their decisions and often spend more.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card justify-content-inherit">
                <div className="card-image">
                    <img
                      src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/05/Boost-close-rate_1.png"
                      alt="boost close rate"
                    />
                </div>
                <div className="card-body">
                  <h3>Boost Your<br/> Close Rate</h3>
                  <p>
                    Offering the right payment options can almost double your
                    close rate. We've found that companies with a 25% baseline
                    can increase their close rate to 44% when they offer a
                    choice of payment options.
                    <sup>†</sup>.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 py-5">
              <p className="text-center">
                <small>
                  <sup>*</sup>HIRI TFG Monthly report, released October 2023
                </small>
              </p>
              <p className="text-center">
                <small>
                  <sup>†</sup>Based on the Brickyard Study commissioned by Regions, 2018
                </small>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p className="text-center">
                <button
                  className="btn btn-info btn-green"
                  onClick={() =>
                    customLink("/sign-up/")}
                >
                  Start Offering Financing Now
                </button>
              </p>
            </div>
          </div>
        </div>
      </section>
      ) : (
        <section className="bg-blue section-padding why-contractors-enerbank py-5">
        <div className="container">
          <div className="row pb-5">
            <div className="col-md-6">
              <h2 className="pb-5 text-white pt-2">
                Why So Many Contractors Choose EnerBank
              </h2>
              <p className="mb-4 text-white">
                No matter how good a contractor is at their job, they won&apos;t
                stay in business if they can&apos;t attract customers. It&apos;s
                vital for contractors to increase their leads and close rate so
                they can show off their ability. When contractors offer a choice
                of home improvement financing from EnerBank, they can see an
                increase in their leads as well bigger project sizes.
              </p>
            </div>
            <div className="col-md-6 text-center">
              <iframe
                className="pt-2"
                src="https://player.vimeo.com/video/825916381?h=a20d926863"
                width="540"
                height="315"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
                title="EnerBank USA - Contractor Financing"
              ></iframe>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="card-image">
                    <img
                      src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/business-expansion.png"
                      alt="business expansion"
                    />
                  </div>
                  <h3>Increase Business Expansion</h3>
                  <p>
                    Research shows that over 55% of home improvement projects
                    are financed in some way<sup>*</sup>. By advertising
                    financing to your customers, you could see a significant
                    increase in leads.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="card-image">
                    <img
                      src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/stay-ahead.png"
                      alt="stay-ahead"
                    />
                  </div>
                  <h3>Stay ahead of your competitors</h3>
                  <p>
                    With so many customers using financing, you may be missing
                    out on opportunities. Those lost customers end up with a
                    competitor, leaving you to settle for the leftovers. By
                    offering financing options, you can retain those customers
                    and stay ahead of the competition.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="card-image">
                    <img
                      src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/multiple-options.png"
                      alt="multiple options"
                    />
                  </div>
                  <h3>Provide customers with options</h3>
                  <p>
                    Financing options let you offer various products at
                    different price points that can fit almost any budget — a
                    "good, better, best" approach to your sales process. When
                    customers see they have greater spending power and more
                    flexibility in their choices, they can be more confident in
                    their decisions and often spend more.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="card-image">
                    <img
                      src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/boost-close-rates.png"
                      alt="boost close rate"
                    />
                  </div>
                  <h3>Boost your close rate</h3>
                  <p>
                    Offering the right payment options can almost double your
                    close rate. We've found that companies with a 25% baseline
                    can increase their close rate to 44% when they offer a
                    choice of payment options.
                    <sup>†</sup>.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 pt-5">
              <p>
                <small>
                  <sup>*</sup>HIRI TFG Monthly report, released October 2023
                </small>
              </p>
              <p>
                <small>
                  <sup>†</sup>Based on the Brickyard Study commissioned by
                  EnerBank USA, 2018.
                </small>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p className="text-center">
                <a
                  className="btn btn-primary outline contractor-financing-button-white-outline"
                  href="#contractor"
                >
                  Start Offering Financing Now
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      )}
    </Layout>
  )
}

export default HomeImprovementFinancing
